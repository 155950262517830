.loading-spinner {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #8e3fb1; /* Purple */
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    margin-left: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}