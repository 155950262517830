#root {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
    color: #333;
    text-align: center;
}

p {
    font-size: 14px;
    color: #666;
}

button {
    padding: 10px 15px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

button.selected {
    background-color: #29732c;
}

button.selected:hover {
    background-color: #276427;
}

button, button.premium {
    transition: background-color 1s ease;
}

button.premium {
    background-color: #b95fdd;
}

button.premium:hover {
    background-color: #a854c9;
}

button.premium.selected {
    background-color: #8e3fb1;
}

button:disabled {
    background-color: #ddd;
    color: #666;
    cursor: not-allowed;
}

button.downloading {
    animation: color-change 3s infinite;
}

button.cancel {
    background-color: #ff9b94;
}

@keyframes color-change {
    0% { background-color: rgb(76, 175, 80); }
    5% { background-color: rgb(73, 170, 77); }
    10% { background-color: rgb(70, 165, 74); }
    15% { background-color: rgb(67, 160, 71); }
    20% { background-color: rgb(64, 155, 68); }
    25% { background-color: rgb(61, 150, 65); }
    30% { background-color: rgb(58, 145, 62); }
    35% { background-color: rgb(55, 140, 59); }
    40% { background-color: rgb(52, 135, 56); }
    45% { background-color: rgb(49, 130, 53); }
    50% { background-color: rgb(46, 125, 50); }
    55% { background-color: rgb(49, 130, 53); }
    60% { background-color: rgb(52, 135, 56); }
    65% { background-color: rgb(55, 140, 59); }
    70% { background-color: rgb(58, 145, 62); }
    75% { background-color: rgb(61, 150, 65); }
    80% { background-color: rgb(64, 155, 68); }
    85% { background-color: rgb(67, 160, 71); }
    90% { background-color: rgb(70, 165, 74); }
    95% { background-color: rgb(73, 170, 77); }
    100% { background-color: rgb(76, 175, 80); }
}

label.title {
    font-weight: bold;
    margin-right: 10px;
}

.premium-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background: rgba(255, 255, 255, 0.95);
  color: #666;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75em;
  white-space: nowrap;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInBadge 0.3s ease forwards;
  animation-delay: 2s;
}

@keyframes fadeInBadge {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.premium-button-container {
  position: relative;
  display: inline-block;
}