.wrap-if-mobile {
    flex-direction: column;
    align-items: center;
}

@media (min-width: 768px) { /* Adjust this value based on your breakpoint needs */
    .wrap-if-mobile {
        flex-direction: row;
        gap: 25px;
    }
}